import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import prevencherChatStyles from "./chat.module.css";
import Message from "../message/message";
import {
  findNextUnansweredQuestion,
  israeliPhoneRegExp,
  prevencherId,
} from "../../utils/utils";
import { useAppSelector } from "../../services/hooks";
import {
  messagesSelector,
  currentQuestionSelector,
  optionsSelector,
  currentQuestionIdSelector,
  errorSelector,
  allAnswersSelector,
  currentQuestionnaireSelector,
  allQuestionsSelector,
  questionnaireCompaniesIdSelector,
} from "../../services/selectors";
import { useActions } from "../../services/hooks/useActions";
import Loader from "../loader/loader";
import {
  useInsertAllAnswersQuestionsMutation,
  useLazyGetCurrentQuestionnaireByNameQuery,
  useLazyGetQuestionByIdQuery,
  useLazyGetUserInfoByIdQuery,
} from "../../services/api/api";
import { TQuestion, TQuestionOption } from "../../services/types";
import { useParams } from "react-router-dom";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function Chat({ u }: { u: null | string }) {
  const { name } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);
  const lastElRef = useRef<HTMLDivElement | null>(null);

  const [getUserInfo] = useLazyGetUserInfoByIdQuery();
  const [getCurrentQuestionnaireByName, { isLoading: isQuestionnaireLoading }] =
    useLazyGetCurrentQuestionnaireByNameQuery();

  useEffect(() => {
    if (name) {
      getCurrentQuestionnaireByName(name);
    }
  }, [getCurrentQuestionnaireByName, name]);

  useEffect(() => {
    if (u) {
      getUserInfo(u).then((res) => {
        if ("data" in res && res.data) {
          setAnswers({
            Id: "0",
            QuestionnaireId: questionnaireId.toString(),
            QuestionsId: "23",
            UsersId: "0",
            Answer: res.data.firstName,
          });
          setAnswers({
            Id: "0",
            QuestionnaireId: questionnaireId.toString(),
            QuestionsId: "24",
            UsersId: "0",
            Answer: res.data.phone,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [u]);

  const user = { id: "guest" };
  const questionnaireId = 26;
  const messages = useAppSelector(messagesSelector);
  const options = useAppSelector(optionsSelector);
  const currentQuestionId = useAppSelector(currentQuestionIdSelector);
  const currentQuestion = useAppSelector(currentQuestionSelector);
  const currentQuestionnaire = useAppSelector(currentQuestionnaireSelector);
  const questionnaireCompaniesId = useAppSelector(
    questionnaireCompaniesIdSelector
  );
  const error = useAppSelector(errorSelector);
  const allAnswers = useAppSelector(allAnswersSelector);
  const allQuestions = useAppSelector(allQuestionsSelector);
  const { setMessages, setOptions, setAnswers } = useActions();
  const [getQuestionById] = useLazyGetQuestionByIdQuery();
  const [insertAllAnswers] = useInsertAllAnswersQuestionsMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (
      currentQuestion &&
      !currentQuestion.NextQuestionId &&
      currentQuestion.DataTypesId !== 1 &&
      currentQuestionnaire?.CompanyId
    ) {
      insertAllAnswers({
        answers: allAnswers,
        companyId: currentQuestionnaire?.CompanyId,
        questionnaireCompaniesId: questionnaireCompaniesId,
      });
    }
  }, [currentQuestion, insertAllAnswers, currentQuestionnaire?.CompanyId]);

  useEffect(() => {
    if (currentQuestionnaire) {
      currentQuestionnaire?.StartQuestion &&
        setMessages({
          messageId: "start",
          id: prevencherId,
          date: new Date(),
          message: currentQuestionnaire.StartQuestion,
          nextQuestionId: 11,
        });
      setIsLoading(true);
      delay(1500).then(() => {
        getQuestionById({
          nextQuestionId: u ? 76 : 11,
          skip: false,
        }).then(() => setIsLoading(false));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionnaire, getQuestionById, setMessages]);

  useEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    if (currentQuestion?.DataTypesId === 2) {
      currentQuestion?.Id === 72 || currentQuestion?.Id === 19
        ? setShowTextArea(true)
        : setShowInput(true);
    }
    if (currentQuestion?.DataTypesId === 5) {
      setIsLoading(true);
      setOptions([]);
      delay(1500).then(() => {
        if (currentQuestion.NextQuestionId) {
          getQuestionById({
            nextQuestionId: currentQuestion.NextQuestionId,
            skip: false,
          }).then(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      });
    }
  }, [currentQuestion, getQuestionById, setOptions]);

  const onOptionClick = (option: TQuestionOption) => {
    setOptions([]);
    setIsLoading(true);
    setMessages({
      messageId: `o${option.Id}`,
      id: user.id,
      date: new Date(),
      unread: false,
      message: option.Desc,
      nextQuestionId: option.NextQuestionId,
    });
    setAnswers({
      Id: "0",
      QuestionnaireId: questionnaireId.toString(),
      QuestionsId: currentQuestionId.toString(),
      UsersId: null,
      Answer: null,
      QuestionsOptionsIds: option.Id.toString(),
      // ...(id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
    });
    delay(1500).then(() => {
      setIsLoading(false);
      const nextQuestion =
        allQuestions &&
        findNextUnansweredQuestion(allQuestions, option.NextQuestionId);
      getQuestionById({
        nextQuestionId:
          u && option.NextQuestionId === 23
            ? 25
            : nextQuestion
            ? nextQuestion.Id
            : option.NextQuestionId,
        skip: !option.NextQuestionId,
      });
    });
  };
  const onInputSubmit = () => {
    if (!inputValue) {
      return;
    }
    if (currentQuestion?.Id === 24 && !israeliPhoneRegExp.test(inputValue)) {
      setMessages({
        messageId: `${inputValue}error`,
        id: prevencherId,
        date: new Date(),
        unread: false,
        message: `${inputValue} - מספר נייד לא תקין`,
        nextQuestionId: currentQuestion?.NextQuestionId,
      });
      return;
    }
    setShowInput(false);
    setShowTextArea(false);
    setIsLoading(true);
    setMessages({
      messageId: `${currentQuestionId}${inputValue}`,
      id: user.id,
      date: new Date(),
      unread: false,
      message: inputValue,
      nextQuestionId: currentQuestion?.NextQuestionId,
    });
    delay(1500).then(() => {
      setAnswers({
        Id: "0",
        QuestionnaireId: questionnaireId.toString(),
        QuestionsId: currentQuestionId.toString(),
        UsersId: "0",
        Answer: inputValue,
        // ...(id ? { Cycle: 1 } : {}), // Only add Cycle if id exists
      });
      setIsLoading(false);
      setInputValue("");
      const nextQuestion =
        allQuestions &&
        currentQuestion?.NextQuestionId &&
        findNextUnansweredQuestion(
          allQuestions,
          currentQuestion.NextQuestionId
        );
      currentQuestion?.NextQuestionId &&
        getQuestionById({
          nextQuestionId: nextQuestion
            ? nextQuestion.Id
            : currentQuestion.NextQuestionId,
          skip: false,
        });
    });
  };
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let sanitizedContent = DOMPurify.sanitize(e.target.value, {
      ALLOWED_TAGS: [], // Allow only certain tags, or use [] for none
      ALLOWED_ATTR: [], // Allow no attributes
    });
    currentQuestion?.Id === 24
      ? handleTelInputChange(e)
      : setInputValue(sanitizedContent);
  };
  const handleTelInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    setInputValue(onlyNumbers);
  };
  const scrollToBottom = () => {
    lastElRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      onInputSubmit();
    }
  };
  const handleFocus = () => {
    inputRef?.current &&
      inputRef.current.scrollIntoView({ behavior: "smooth" });
  }; //for ios mobile

  function hasNextQuestion(
    currentQuestion: TQuestion,
    options: TQuestionOption[]
  ) {
    // Return false if neither currentQuestion.NextQuestionId nor options[0].NextQuestionId exist
    return !(currentQuestion?.NextQuestionId || options[0]?.NextQuestionId);
  }
  return (
    <div
      className={`${prevencherChatStyles.section} ${prevencherChatStyles.section_POC}`}
    >
      {messages.length > 0 &&
        messages.map((message) => (
          <Message
            key={message.messageId}
            isOwnMessage={user.id === message.id}
            message={message}
          />
        ))}
      {options.length > 0 && (
        <ul
          className={`${prevencherChatStyles.variants} ${prevencherChatStyles.variants_poc}`}
        >
          {options.map((o) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={o.Id}
              onClick={() => {
                onOptionClick(o);
              }}
            >
              <span>{o.Desc}</span>
            </li>
          ))}
        </ul>
      )}
      {showInput && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <input
            type={"text"}
            ref={inputRef}
            onFocus={handleFocus}
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            maxLength={currentQuestion?.Id === 23 ? 10 : 300}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
            onClick={() => {}}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      {showTextArea && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <textarea
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      <div
        className={`loader ${
          (isLoading || isQuestionnaireLoading) && "loader_visible"
        }`}
      >
        <Loader />
        <p className={prevencherChatStyles.typing}>ונצ'י מקליד</p>
      </div>
      {error && <p className={"error"}>{error}</p>}
      <div ref={lastElRef} />
    </div>
  );
}

export default Chat;
